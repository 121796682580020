import styled from 'styled-components';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.75rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-width: 350px;

  &[data-is-checked='true'] {
    border-color: ${({ theme }) => theme.primary};
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #757575;
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;

  .MuiFormControlLabel-root {
    margin: 0;
    gap: 0.5rem;
    color: ${({ theme }) => theme.primary};
  }
  .MuiFormControlLabel-label {
    font-family: 'Poppins Medium';
  }
  .MuiRadio-root {
    padding: 0;
  }
`;

export const Divider = styled('div')`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ddd;
`;

export const Details = styled('div')`
  .value {
    font-family: 'Poppins Medium';
    color: #424242;
  }
`;

import React from 'react';
import NumberFormat from 'react-number-format';
import Select from 'modules/financing/components/atoms/Select';
import AutoComplete from 'components/atoms/AutoComplete';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  CNPJMaskCustom,
  TextMaskCustom,
  CEPMaskCustom,
} from 'components/MaskTextInput';
import TextInput from 'modules/financing/components/atoms/TextField';
import SearchButton from 'modules/financing/components/atoms/SearchButton';
import getDateByDatePickerComponent from 'modules/financing/helpers/getDateByDatePickerComponent';
import { ReactComponent as ContentCopyIcon } from 'assets/content-copy-icon.svg';
import RoundedButton from 'modules/financing/components/atoms/RoundedButton';
import contentCopyByNameInput from 'modules/financing/components/molecules/ClientCompleteDataForm/helpers/contentCopyByNameInput';

const OccupationForm = props => {
  const {
    statesOptions,
    employmentCitiesOptions,
    streetTypeOptions,
    careersOptions,
    employmentsOptions,
    handleKeyPress,
    getAddresInfosByZipCode,
    formData,
    onChange,
    disableAllInputs,
    enableCopyButton,
  } = props;
  const isAutonomous =
    formData['employmentId'] === 4 || formData['employmentId'] === 8;
  return (
    <div>
      {/* GRID_CONTAINER */}
      <Grid container spacing={1}>
        {/* ROW */}
        <Grid item xs={6}>
          <Select
            id="employmentId"
            name="employmentId"
            label="Tipo de Ocupação"
            value={formData['employmentId']}
            onChange={onChange}
            options={employmentsOptions}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={6}>
          <AutoComplete
            name="careerId"
            label="Profissão"
            options={careersOptions}
            value={
              formData?.['careerId']
                ? {
                    id: formData['careerId'],
                    value: formData['careerId'],
                    label: careersOptions.find(
                      e => e.value === formData['careerId']
                    )?.label,
                    name: careersOptions.find(
                      e => e.value === formData['careerId']
                    )?.label,
                  }
                : null
            }
            onChange={e =>
              onChange({ target: { value: e?.value, name: 'careerId' } })
            }
            disabled={disableAllInputs}
            minHeight="58px"
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={3}>
          <TextInput
            id="employmentSocialName"
            name="employmentSocialName"
            label="Nome do empregador"
            value={formData['employmentSocialName']}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('employmentSocialName')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            id="employmentStartDate"
            name="employmentStartDate"
            type="date"
            label="Data que iniciou na empresa"
            value={getDateByDatePickerComponent(
              formData['employmentStartDate']
            )}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('employmentStartDate')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <NumberFormat
            value={formData['monthlyIncome']}
            onValueChange={({ floatValue }): void => {
              onChange({
                target: { name: 'monthlyIncome', value: floatValue },
              });
            }}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            allowLeadingZeros={false}
            placeholder="0"
            prefix="R$ "
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextInput}
            label={'Renda'}
            disabled={disableAllInputs}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="employerCompanyPhone"
            name="employerCompanyPhone"
            label="Telefone"
            value={formData['employerCompanyPhone']}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              inputComponent: TextMaskCustom,
              onChange: onChange,
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('employerCompanyPhone')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
            required
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="employerCompanyCnpj"
            name="employerCompanyCnpj"
            label="CNPJ"
            value={formData['employerCompanyCnpj']}
            disabled={isAutonomous || disableAllInputs}
            onChange={onChange}
            InputProps={{
              inputComponent: CNPJMaskCustom,
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('employerCompanyCnpj')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={3}>
          <TextInput
            id="employmentZipcode"
            name="employmentZipcode"
            label="CEP da ocupação"
            value={formData['employmentZipcode']}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              inputComponent: CEPMaskCustom,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchButton onClick={getAddresInfosByZipCode} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            id="employmentStreetType"
            name="employmentStreetType"
            label="Tipo do logradouro"
            value={formData['employmentStreetType']}
            onChange={onChange}
            options={streetTypeOptions}
            disabled={isAutonomous || disableAllInputs}
          />
        </Grid>
        <Grid item xs={5}>
          <TextInput
            id="employmentAddress"
            name="employmentAddress"
            label="Endereço"
            value={formData['employmentAddress']}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('employmentAddress')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            id="employmentNumber"
            name="employmentNumber"
            label="Número"
            value={formData['employmentNumber']}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('employmentNumber')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={12}>
          <TextInput
            id="employmentComplement"
            name="employmentComplement"
            label="Complemento"
            value={formData['employmentComplement']}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() => contentCopyByNameInput('employmentComplement')}
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        {/* ROW */}
        <Grid item xs={4}>
          <TextInput
            id="employmentNeighborhood"
            name="employmentNeighborhood"
            label="Bairro"
            value={formData['employmentNeighborhood']}
            onChange={onChange}
            disabled={isAutonomous || disableAllInputs}
            InputProps={{
              endAdornment: enableCopyButton ? (
                <RoundedButton
                  onClick={() =>
                    contentCopyByNameInput('employmentNeighborhood')
                  }
                >
                  <ContentCopyIcon />
                </RoundedButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="employmentCityStateId"
            label="Estado"
            options={statesOptions}
            value={
              formData?.['employmentCityStateId']
                ? {
                    id: formData['employmentCityStateId'],
                    value: formData['employmentCityStateId'],
                    label: statesOptions.find(
                      e => e.value === formData['employmentCityStateId']
                    )?.label,
                    name: statesOptions.find(
                      e => e.value === formData['employmentCityStateId']
                    )?.label,
                  }
                : null
            }
            onChange={e =>
              onChange({
                target: { value: e?.value, name: 'employmentCityStateId' },
              })
            }
            disabled={isAutonomous || disableAllInputs}
            minHeight="58px"
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            name="employmentCityId"
            label="Cidade*"
            options={employmentCitiesOptions}
            value={
              formData?.['employmentCityId']
                ? {
                    id: formData['employmentCityId'],
                    value: formData['employmentCityId'],
                    label: employmentCitiesOptions.find(
                      e => e.value === formData['employmentCityId']
                    )?.label,
                    name: employmentCitiesOptions.find(
                      e => e.value === formData['employmentCityId']
                    )?.label,
                  }
                : null
            }
            onChange={e =>
              onChange({ target: { value: e.value, name: 'employmentCityId' } })
            }
            disabled={isAutonomous || disableAllInputs}
            minHeight="58px"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OccupationForm;

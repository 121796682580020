import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Socket } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import useSnackBar from 'hooks/useSnackBar';
import { useSocketContext } from 'socketContext';

import {
  GetAllInsuranceQuoteByProposalIdQ,
  GetAllInsuranceQuoteByProposalIdR,
  InsuranceQuoteData,
  InsuranceQuoteStatus,
} from 'modules/insureQuotes/services/dto';
import {
  useGetAllInsuranceQuoteByProposalId,
  useGetBestsInsuranceQuoteByProposalId,
  useMarkSelectedInsuranceQuote,
} from 'modules/insureQuotes/services/queries';

import { AlertSync } from './AlertSync';
import { QuoteItem } from './QuoteItem';

import * as S from './styles';

interface QuotesGroups {
  quotes: InsuranceQuoteData[];
  vehicleName: string;
}

interface Props {
  viewMode?: 'order' | 'proposal';
}
  
export function QuotesList({ viewMode = 'proposal' }: Props) {
  const CURRENT_SOCKET_MODULE = 'insurance-quotes';
  const CURRENT_SOCKET_EVENT = `${CURRENT_SOCKET_MODULE}:updated`;

  const [quotesGroups, setQuotesGroups] = useState<QuotesGroups[]>([]);
  const [selectedQuoteId, setSelectedQuoteId] = useState<number | null>(null);

  const { currentDealer, proposalId } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
    proposalId: state.proposalStore.currentProposal.proposalId,
  }));

  const { success } = useSnackBar();

  const { socket }: any = useSocketContext();
  const contextSocket = socket as Socket;

  const queryParams: GetAllInsuranceQuoteByProposalIdQ = useMemo(
    () => ({ dealerId: currentDealer.id, proposalId }),
    [currentDealer, proposalId]
  );

  const {
    data: dataAllInsuranceQuotes,
    isLoading: loadingAllInsuranceQuotes,
    isFetching: fetchingAllInsuranceQuotes,
    isRefetching: refetchingAllInsuranceQuotes,
    refetch: refetchAllInsuranceQuotes,
  } = useGetAllInsuranceQuoteByProposalId(queryParams, viewMode === 'proposal');

  const {
    data: dataBestInsuranceQuotes,
    isLoading: loadingBestInsuranceQuotes,
    isFetching: fetchingBestInsuranceQuotes,
  } = useGetBestsInsuranceQuoteByProposalId(queryParams, viewMode === 'order');

  const loadingInsuranceQuotes =
    loadingAllInsuranceQuotes ||
    fetchingAllInsuranceQuotes ||
    refetchingAllInsuranceQuotes ||
    loadingBestInsuranceQuotes ||
    fetchingBestInsuranceQuotes;

  const {
    mutateAsync: selectInsurance,
    isLoading: loadingSelectInsurance,
  } = useMarkSelectedInsuranceQuote({
    onSuccess: selectedId => {
      setSelectedQuoteId((selectedId as unknown) as number);
      return success('Cotação selecionada com sucesso.');
    },
  });

  const groupArrayByCustomKey = <T, K extends keyof T>({
    array,
    key,
  }: {
    array: T[];
    key: K;
  }) => {
    return array.reduce((result, currentItem) => {
      const groupKey = currentItem[key] ? String(currentItem[key]) : '-';

      if (!result[groupKey]) result[groupKey] = [];
      result[groupKey].push(currentItem);

      return result;
    }, {} as { [key: string]: T[] });
  };

  const handleOnSelectInsurance = useCallback(
    async (insuranceId: number) => {
      await selectInsurance({
        dealerId: currentDealer.id,
        insuranceId,
        proposalId,
      });
    },

    [currentDealer, proposalId]
  );

  const hasDesynchronizedQuotesOnGroup = (group: QuotesGroups) => {
    return group.quotes?.some(
      ({ status }) => status === InsuranceQuoteStatus.New
    );
  };

  const allQuotesAreNew = (groups: QuotesGroups[]) => {
    return groups?.every(group => {
      return group.quotes?.every(
        ({ status }) => status === InsuranceQuoteStatus.New
      );
    });
  };

  const getInitSelectedQuote = (values: GetAllInsuranceQuoteByProposalIdR) => {
    const initSelectedQuote = values?.find(value => value.isSelected);
    return initSelectedQuote ? initSelectedQuote.id : null;
  };

  const formatInsuranceQuotes = (
    values: GetAllInsuranceQuoteByProposalIdR
  ): QuotesGroups[] => {
    const groupedQuotesByVehicle = groupArrayByCustomKey({
      array: values,
      key: 'vehicle',
    });

    const formattedGroups: QuotesGroups[] = Object.entries(
      groupedQuotesByVehicle
    ).map(([groupKey, values]) => {
      return { vehicleName: groupKey, quotes: values };
    });

    return formattedGroups;
  };

  const syncedQuotesGroups = quotesGroups?.filter(quoteItem =>
    quoteItem?.quotes?.some(
      quote => quote?.status === InsuranceQuoteStatus.Synced
    )
  );

  const hasQuoteGroups = syncedQuotesGroups?.length > 0;

  const hasValidQuotes = hasQuoteGroups && !allQuotesAreNew(quotesGroups);

  const quotesDataMap: Record<
    string,
    GetAllInsuranceQuoteByProposalIdR | GetAllInsuranceQuoteByProposalIdR
  > = {
    proposal: dataAllInsuranceQuotes,
    order: dataBestInsuranceQuotes,
  };

  const activeQuotesData = quotesDataMap[viewMode] || null;

  const filterQuotesByViewMode = (viewMode: string, group: QuotesGroups) => {
    if (viewMode === 'order') {
      const hasSelected = group?.quotes.some(
        quoteItem => quoteItem?.isSelected
      );
      return group?.quotes.filter(quote =>
        hasSelected ? quote?.isSelected : true
      );
    }
    return group?.quotes || [];
  };

  useEffect(() => {
    if (!activeQuotesData) return;

    const formattedGroups = formatInsuranceQuotes(activeQuotesData);
    setQuotesGroups(formattedGroups);

    const initSelected = getInitSelectedQuote(activeQuotesData);
    setSelectedQuoteId(initSelected);
  }, [activeQuotesData]);

  useEffect(() => {
    if (!contextSocket) return;

    contextSocket.on(CURRENT_SOCKET_MODULE, ({ type }) => {
      if (type === CURRENT_SOCKET_EVENT) refetchAllInsuranceQuotes();
    });

    return () => contextSocket.off(CURRENT_SOCKET_MODULE);
  }, [queryParams]);

  return (
    <Fragment>
      <S.Container>
        {loadingInsuranceQuotes && <AlertSync />}
        {!loadingInsuranceQuotes && (
          <Fragment>
            {!hasValidQuotes && (
              <S.EmptyInfo>Não há histórico de cotações.</S.EmptyInfo>
            )}
            {!!hasValidQuotes && (
              <S.Items data-view-mode={viewMode}>
                {syncedQuotesGroups?.map(group => {
                  const groupHasQuotes = group?.quotes?.length > 0;
                  const loadingResults = hasDesynchronizedQuotesOnGroup(group);
                  return (
                    <S.Item key={group.vehicleName} data-view-mode={viewMode}>
                      <S.ItemTitle>{group.vehicleName}</S.ItemTitle>
                      <S.Accordion>
                        <S.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <p>Cotações realizadas</p>
                          {loadingResults && (
                            <S.StatusChip
                              size="small"
                              label="Carregando resultados"
                            />
                          )}
                        </S.AccordionSummary>
                        <S.AccordionDetails>
                          {!groupHasQuotes && (
                            <S.EmptyInfo>
                              Nenhuma cotação disponível para esse veículo.
                            </S.EmptyInfo>
                          )}
                          {groupHasQuotes && (
                            <S.ItemContent>
                              <S.ItemSubtitle>
                                Selecione uma cotação caso deseje priorizá-la:
                              </S.ItemSubtitle>
                              <S.ItemContent data-view-mode={viewMode}>
                                {filterQuotesByViewMode(viewMode, group).map(
                                  (quote, quoteIndex) => (
                                    <QuoteItem
                                      key={quote.id}
                                      quote={quote}
                                      quoteIndex={quoteIndex}
                                      isLoading={loadingSelectInsurance}
                                      onSelectQuote={selectedId => {
                                        return handleOnSelectInsurance(
                                          selectedId
                                        );
                                      }}
                                      {...(selectedQuoteId !== null && {
                                        selectedQuoteId,
                                      })}
                                    />
                                  )
                                )}
                              </S.ItemContent>
                            </S.ItemContent>
                          )}
                        </S.AccordionDetails>
                      </S.Accordion>
                    </S.Item>
                  );
                })}
              </S.Items>
            )}
          </Fragment>
        )}
      </S.Container>
    </Fragment>
  );
}

import envVars from 'helpers/envVars';
import request from 'helpers/request';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

import * as DTO from './dto';

const domain = envVars('API_PAINEL_DIGITAL_BASE_URL');

export async function getAllInsuranceQuoteByProposalId({
  dealerId,
  proposalId,
}: DTO.GetAllInsuranceQuoteByProposalIdQ) {
  const response = await request
    .get<DTO.GetAllInsuranceQuoteByProposalIdR>(
      `${domain}/dealer/${dealerId}/proposal/${proposalId}/insurance-quote/getall/v1.0`
    )
    .then(response => {
      buildHTTPResponseHandler(
        `insureQuotes.getAllInsuranceQuoteByProposalId()`
      );
      return response?.data;
    });

  return response;
}

export async function getBestsInsuranceQuoteByProposalId({
  dealerId,
  proposalId,
}: DTO.GetAllInsuranceQuoteByProposalIdQ) {
  const response = await request
    .get<DTO.GetAllInsuranceQuoteByProposalIdR>(
      `${domain}/dealer/${dealerId}/proposal/${proposalId}/insurance-quote/getbests/v1.0`
    )
    .then(response => {
      buildHTTPResponseHandler(
        `insureQuotes.getBestsInsuranceQuoteByProposalId()`
      );
      return response?.data;
    });

  return response;
}

export async function startInsuranceQuote({
  dealerId,
  proposalId,
}: DTO.StartInsuranceQuoteQ) {
  const response = await request
    .post(
      `${domain}/dealer/${dealerId}/proposal/${proposalId}/insurance-quote/v1.0`
    )
    .then(response => {
      buildHTTPResponseHandler(`insureQuotes.startInsuranceQuote()`);
      return response?.data;
    });

  return response;
}

export async function markSelectedInsuranceQuote({
  dealerId,
  insuranceId,
  proposalId,
}: DTO.MarkSelectedInsuranceQuoteQ) {
  const response = await request
    .put(
      `${domain}/dealer/${dealerId}/proposal/${proposalId}/insurance-quote/v1.0/selected/${insuranceId}`,
      {}
    )
    .then(response => {
      buildHTTPResponseHandler(`insureQuotes.markSelectedInsuranceQuote()`);
      return response?.data;
    });

  return response;
}

export async function sendInsuranceQuoteToSpecialist({
  dealerId,
  proposalId,
}: DTO.SendInsuranceQuoteToSpecialistQ) {
  const response = await request
    .put(
      `${domain}/dealer/${dealerId}/proposal/order/v1.0/insurance/${proposalId}/send-quote-to-specialist`,
      {}
    )
    .then(response => {
      buildHTTPResponseHandler(`order.sendInsuranceQuoteToSpecialist()`);
      return response?.data;
    });

  return response;
}

export async function sendInsuranceQuoteToCustomer({
  dealerId,
  proposalId,
}: DTO.SendInsuranceQuoteToCustomerQ) {
  const response = await request
    .put(
      `${domain}/dealer/${dealerId}/proposal/order/v1.0/insurance/${proposalId}/send-quote-to-customer`,
      {}
    )
    .then(response => {
      buildHTTPResponseHandler(`order.sendInsuranceQuoteToCustomer()`);
      return response?.data;
    });

  return response;
}

import React, { Fragment, useState } from 'react';

import TabPanel from 'components/StyledComponents/TabPanel';
import StyledTab from 'components/StyledComponents/StyledTab';
import StyledTabs from 'components/StyledComponents/StyledTabs';

import ItemHeader from '../atoms/ItemHeader';

import { QuotesList } from 'modules/insureQuotes/components/QuotesList';
import { StartQuoteFlow } from 'modules/insureQuotes/components/StartQuoteFlow';
import { getParamsToCreateInsuranceProposal } from 'modules/insureQuotes/utils/functions';

import { useChatContext } from 'pages/chats/ChatsContext';

import * as S from './styles';

enum AvailableTabs {
  Simulation = 0,
  MyQuotes = 1,
}

export function InsuranceProposal() {
  const [currentTab, setCurrentTab] = useState<AvailableTabs>(
    AvailableTabs.Simulation
  );

  const { currentProposal }: any = useChatContext();

  const createIframeParams = (): string => {
    const currentVehicle = currentProposal?.vehicle?.length
      ? currentProposal?.vehicle[0]
      : undefined;

    const formattedParams = getParamsToCreateInsuranceProposal({
      proposalId: currentProposal?.id,
      clientEmail: currentProposal?.clientEmail,
      clientUserCpfCnpj: currentProposal?.clientUserCpfCnpj,
      clientUserName: currentProposal?.clientUserName,
      clientUserPhone: currentProposal?.clientUserPhone,
      ...(currentVehicle && {
        modelOrModelName: currentVehicle?.model || currentVehicle?.modelName,
        modelYear: currentVehicle?.modelYear,
        plate: currentVehicle?.plate,
        productionYear: currentVehicle?.productionYear,
        vin: currentVehicle?.vin,
      }),
    });

    return formattedParams;
  };

  return (
    <Fragment>
      <ItemHeader>Seguro</ItemHeader>
      <StyledTabs
        value={currentTab}
        onChange={(_, newTab) => setCurrentTab(newTab as AvailableTabs)}
      >
        <StyledTab value={AvailableTabs.Simulation} label="Simulação" />
        <StyledTab value={AvailableTabs.MyQuotes} label="Minhas cotações" />
      </StyledTabs>
      <TabPanel
        key={'Simulação'}
        value={currentTab}
        index={AvailableTabs.Simulation}
      >
        {currentTab === 0 && (
          <S.ContainerButtonQuote>
            <StartQuoteFlow iframeParams={createIframeParams()} />
          </S.ContainerButtonQuote>
        )}
      </TabPanel>
      <TabPanel
        key={'Minhas cotações'}
        value={currentTab}
        index={AvailableTabs.MyQuotes}
      >
        {currentTab === 1 && <QuotesList viewMode="proposal" />}
      </TabPanel>
    </Fragment>
  );
}

import { create } from 'apisauce';
import NProgress from 'nprogress';
import EnvVars from 'helpers/envVars';

import routesPath from 'config/routesPath';

import { getToken, getRefreshToken, updateToken } from 'auth';

const api = create({
  baseURL: '/',
  headers: { accept: '*/*' },
});

const { interceptors } = api.axiosInstance;

const decodeJWT = token => {
  if (!token) return null;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

const checkTokenIsExpired = tokenExp => {
  const nowInSeconds = Math.floor(Date.now() / 1000);
  return tokenExp < nowInSeconds;
};

interceptors.request.use(
  async request => {
    NProgress.start();

    if (
      request?.url.includes(EnvVars('API_PAINEL_DIGITAL_BASE_URL_PUBLIC')) ||
      request?.url.includes('sagadatadriven')
    ) {
      NProgress.done();
      return request;
    }

    const currentRequest = request;
    const decodedToken = decodeJWT(getToken());
    const decodedRefreshToken = decodeJWT(getRefreshToken());

    if (!decodedToken || !decodedRefreshToken) {
      NProgress.done();
      return Promise.reject('Invalid access or refresh token');
    }

    const tokenIsExpired = checkTokenIsExpired(decodedToken.exp);
    const refreshTokenIsExpired = checkTokenIsExpired(decodedRefreshToken.exp);

    if (refreshTokenIsExpired) {
      window.location.href = routesPath.getRecursivePath(
        routesPath.mobigestorHome
      );
      return Promise.reject('Invalid refresh token');
    }

    if (tokenIsExpired) {
      try {
        await updateToken();
      } catch (error) {
        NProgress.done();
        return Promise.reject(error);
      }
    }

    currentRequest.headers = {
      ...request.headers,
      Authorization: `Bearer ${getToken()}`,
    };

    NProgress.done();
    return currentRequest;
  },
  error => {
    NProgress.done();
    return Promise.reject(error);
  }
);

interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  error => {
    NProgress.done();
    return Promise.reject(error);
  }
);

export default api;

import colors from 'bit/mobiauto.web-ui-components.components.colors/colors';
import Flex from 'bit/mobiauto.web-ui-components.flex/Flex';
import styled from 'styled-components';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

export const ExpansionPanelContainer = styled.div`
  & .MuiPaper-root {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
  }
`;

export const SummaryContainer = styled(Flex)`
  width: 100%;
  margin: 0 auto;
  padding: 0 !important;
`;

export const SummaryText = styled.div`
  flex: 1;
  color: ${colors.text.normal};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
`;

export const DetailsContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  padding: 20px 16px 16px 16px
  box-sizing: border-box;
`;

export const DetailsItem = styled.div`
  flex-basis: 45%;
  padding: 10px 0;
`;

export const DetailsItemLabel = styled.div`
  color: ${colors.text.disabled};
  font-size: 14px;
`;

export const DetailsItemValue = styled.div`
  color: ${colors.text.normal};
  font-size: 14px;
`;

export const IconAnimation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: rotate(0deg);
  &.expanded {
    transform: rotate(180deg);
  }
`;

export const ExpansionIcon = styled(KeyboardArrowDown)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.primary};
`;
